@font-face {
    font-family: 'JetBrains Sans';
    font-weight: normal;
    src: url('JetBrainsSans-Regular.woff2') format('woff2')
}

@font-face {
    font-family: 'JetBrains Sans';
    font-weight: bold;
    src: url('JetBrainsSans-Bold.woff2') format('woff2')
}

@font-face {
    font-family: 'JetBrains Sans';
    font-weight: 100;
    src: url('JetBrainsSans-Thin.woff2') format('woff2')
}

@font-face {
    font-family: 'JetBrains Sans';
    font-weight: 200;
    src: url('JetBrainsSans-Light.woff2') format('woff2')
}

@font-face {
    font-family: 'JetBrains Sans';
    font-weight: 300;
    src: url('JetBrainsSans-Book.woff2') format('woff2')
}

@font-face {
    font-family: 'JetBrains Sans';
    font-weight: 500;
    src: url('JetBrainsSans-Medium.woff2') format('woff2')
}

@font-face {
    font-family: 'JetBrains Sans';
    font-weight: 600;
    src: url('JetBrainsSans-SemiBold.woff2') format('woff2')
}

@font-face {
    font-family: 'JetBrains Sans';
    font-weight: 600;
    src: url('JetBrainsSans-Bold.woff2') format('woff2')
}

@font-face {
    font-family: 'JetBrains Sans';
    font-weight: 700;
    src: url('JetBrainsSans-ExtraBold.woff2') format('woff2')
}

@font-face {
    font-family: 'JetBrains Sans';
    font-weight: 800;
    src: url('JetBrainsSans-Ultra.woff2') format('woff2')
}
