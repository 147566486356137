a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, html, i, iframe, img, ins, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}

::-moz-selection { /* Code for Firefox */
    background: #9135e0;
}

::selection {
    background: #9135e0;
}

html {
    font-size: 16px;
}

body {
    font-family: 'JetBrains Sans', Helvetica, sans-serif;
    background-color: #171819;
    color: white;
}

.wt-layout__content {
    overflow: hidden;
}

.response {
    max-width: calc(100% - 20vw);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    padding-left: 5vw;
    padding-right: 5vw;
}

.wt-hero {
    font-size: 5rem;
    font-weight: 400;
}

.wt-subtitle-1 {
    font-size: 2.75rem;
    font-weight: 400;
    letter-spacing: 0.035em;
    -webkit-font-smoothing: antialiased;
    text-transform: uppercase;
}

.wt-h3 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5;
}

.wt-text-1 {
    font-size: 1.4rem;
    line-height: 1.65;
    font-weight: 300;
    letter-spacing: 0.006em;
}

.wt-text-2 {
    font-size: 1rem;
    line-height: 1.65;
    font-weight: 300;
}

button {
    font-size: 1rem;
}

a.text-link {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid currentColor;
    display: inline-block;
}

a.text-link:hover {
    border-bottom: 1px solid #a6ff47;
}

.wt-offset-top-16 {
    margin-top: 16px;
}

.wt-offset-top-8 {
    margin-top: 8px;
}

.jb-logo {
    width: 4.5rem;
}

.goose {
    display: block;
    width: 10rem;
    height: 10rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('img/goose.svg');
}

/*.goose:hover {*/
/*    background-image: url('img/goose-hover.svg');*/
/*}*/

.gray-card {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 1rem;
    overflow: hidden;
    z-index: 1;
}

.gray-card:hover .card-image {
    transform: scale(1.15);
}

.mask {
    overflow: hidden;
}

.card-image {
    background-color: black;
    width: 100%;
    height: 20rem;
    background-position: center;
    background-size: cover;
    transition: transform 0.75s;
}

.card-description {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    box-sizing: border-box;
}

.project {
    display: flex;
    height: 100%;
    width: 100%;
    text-decoration: none;
    border-radius: 1rem;
    padding: 1.5rem;
    box-sizing: border-box;
    color: white;
    background-color: rgba(255, 255, 255, 0.05);
}

.project:hover {
    background-color: rgba(255, 255, 255, 0.15);
    text-decoration: none;

}

.project:hover p, .gray-card:hover p {
    color: white;
}

.big-banner {
    position: relative;
    height: 35vw;
    background-color: black;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 1rem;
    z-index: 1;
}

.big-banner:hover .banner-image {
    transform: scale(1.1);
}

.banner-content {
    z-index: 2;
}

.banner-content .wt-hero {
    text-transform: uppercase;
}

.banner-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    transition: transform .75s;
}

.icon {
    width: 64px;
    height: 64px;
    background-size: cover;
    background-repeat: no-repeat;
}

.tiler .banner-image {
    background-image: url('img/projects/tiler.jpg');
}

.neobeam .banner-image {
    background-image: url('img/projects/neobeam.jpg');
}

.lorenz-system .banner-image {
    background-image: url('img/projects/lorenz.jpg');
}

.dots .banner-image {
    background-image: url('img/projects/dots.jpg');
}

.seti .banner-image {
    background-image: url('img/projects/seti.jpg');
}

.waver .banner-image {
    background-image: url('img/projects/waver.png');
}

.origami .banner-image {
    background-image: url('img/projects/origami.jpg');
}

.jelly .banner-image {
    background-image: url('img/projects/jelly.jpg');
}

.randolor .banner-image {
    background-image: url('img/projects/randolor.jpg');
}

.plasmatic .banner-image {
    background-image: url('img/projects/plasmatic.jpg');
}

.nautilus .banner-image {
    background-image: url('img/projects/nautilus.jpg');
}


.eidos .banner-image {
    background-image: url('img/projects/eidos.jpg');
}

.gradient .banner-image {
    background-image: url('img/projects/gradient.png');
}
.icon.limb {
    background-image: url('img/project-icon/limb.svg');
}

.icon.barista {
    background-image: url('img/project-icon/barista.svg');
}

.icon.eidos {
    background-image: url('img/project-icon/eidos.svg');
}


.icon.plasmatic {
    background-image: url('img/project-icon/plasmatic.svg');
}

.icon.coverbaker {
    background-image: url('img/project-icon/coverbaker.svg');
}

.icon.nautilus {
    background-image: url('img/project-icon/nautilus.svg');
}

.icon.gradient {
    background-image: url('img/project-icon/gradient.svg');
}


.icon.waver {
    background-image: url('img/project-icon/waver.svg');
}

.icon.tiler {
    background-image: url('img/project-icon/tiler.svg');
}

.icon.dots {
    background-image: url('img/project-icon/dots.svg');
}

.icon.kvant {
    background-image: url('img/project-icon/kvant.svg');
}

.icon.randolor {
    background-image: url('img/project-icon/randolor.svg');
}

.icon.noodle {
    background-image: url('img/project-icon/noodle.svg');
}

.icon.tron {
    background-image: url('img/project-icon/tron.svg');
}

.icon.jelly {
    background-image: url('img/project-icon/jelly.svg');
}

.icon.publo {
    background-image: url('img/project-icon/publo.svg');
}

.icon.raygun {
    background-image: url('img/project-icon/raygun.svg');
}

.icon.neobeam {
    background-image: url('img/project-icon/neobeam.svg');
}

.icon.lorenz {
    background-image: url('img/project-icon/lorenz.svg');
}

.icon.seti {
    background-image: url('img/project-icon/seti.svg');
}

.icon.origami {
    background-image: url('img/project-icon/origami.svg');
}
.icon.metaball {
    background-image: url('img/project-icon/metaball.svg');
}
.icon.jetfusion {
    background-image: url('img/project-icon/jetfusion.svg');
}

.jetfusion .banner-image {
    background-image: url('img/projects/jetfusion.png');
}

@media screen and (min-width: 320px)  and (max-width: 799px) {
    .goose{
        height: 5rem;
    }
    .response {
        max-width: 100%;
    }

    .wt-text-1 {
        font-size: 1.25rem;
    }

    .wt-subtitle-1 {
        font-size: 2.25rem;
    }

    .wt-hero {
        font-size: 3rem;
    }

    .big-banner {
        height: 22rem;
        padding: 1.5rem;
    }

    .card-image {
        height: 12rem;
    }
}

@media screen and (min-width: 800px)  and (max-width: 1199px) {
    .wt-hero {
        font-size: 5rem;
    }

    .wt-subtitle-1 {
        font-size: 2.75rem;
    }

    .response {
        max-width: 100%;
    }

    .card-image {
        height: 12rem;
    }

    .big-banner {
        height: 28rem;
        padding: 2rem;

    }
}

@media screen and (min-width: 1200px) and (max-width: 1699px) {
    .wt-hero {
        font-size: 5rem;
    }

    .card-image {
        height: 18rem;
    }

    .big-banner {
        height: 28rem;
    }

    html {
        font-size: 18px;
    }

    .wt-subtitle-1 {
        font-size: 2.75rem;
    }

    .response {
        max-width: calc(100% - 2vw);
    }
}

@media screen and (min-width: 1700px) {
    .wt-subtitle-1 {
        font-size: 3.25rem;
    }

    .card-image {
        height: 18rem;
    }

    .wt-hero {
        font-size: 7rem;
    }

    .response {
        max-width: calc(100% - 15vw);
    }

    html {
        font-size: 18px;
    }

    .big-banner {
        height: 32rem;
    }
}

@media screen and (min-width: 1920px) {
    .response {
        max-width: calc(100% - 15vw);
    }

    html {
        font-size: 20px;
    }
}